.blog {
  padding-bottom: 100px;
  .container {
    position: relative;
    .blog-newsletter {
      overflow: hidden;
      max-width: 460px;
      margin: 0 auto;
    }
    .blog-banner {
      position: relative;
      img {
        width: 100%;
        height: 260px;
        object-fit: cover;
      }
      .overlay {
        background-image: linear-gradient(
          359.8deg,
          #3b4755 2.2%,
          #222931 99.3%
        );
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        opacity: 0.8;
        display: flex;
        justify-content: center;
        align-items: center;

        .title {
          flex: 1;
          color: #fff;
          max-width: 100%;
          padding: 0 20px;
          font-size: 1.8rem;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }

    .blog-posts {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
      margin: 50px auto 0;
      padding: 20px;
    }
    .pagination-container {
      max-width: 200px;
      margin: 0 auto;
    }

    .pagination {
      padding: 0 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      margin-top: 20px;
      li {
        margin: 0 5px;
        cursor: pointer;
        font-size: 1.2rem;
        color: #fff;
        padding: 5px 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
      li.active {
        background-color: #007bff;
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: 584px) {
  .blog {
    .container {
      .blog-posts {
        max-width: 100%;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media screen and (min-width: 784px) {
  .blog {
    .container {
      .blog-banner {
        img {
          height: 300px;
        }
      }
      .blog-posts {
        max-width: 90%;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .blog {
    .container {
      .blog-banner {
        img {
          height: 360px;
        }
      }
      .blog-posts {
        max-width: 90%;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .blog {
    .container {
      .blog-banner {
        img {
          height: 420px;
        }
      }
      .blog-posts {
        max-width: 90%;
      }
    }
  }
}
