.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .not-found-container {
    text-align: center;
    h1 {
      color: #fff;
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 2rem;
      color: #d3d3d3;
    }
    .button {
      display: inline-block;
      padding: 0.75rem 1.5rem;
      background-color: #007bff;
      color: #fff;
      text-decoration: none;
      border-radius: 4px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
