video {
  width: 100%;
  height: 260px;
  object-fit: cover;
}

@media screen and (min-width: 784px) {
  video {
    height: 300px;
  }
}

@media screen and (min-width: 1024px) {
  video {
    height: 360px;
  }
}

@media screen and (min-width: 1200px) {
  video {
    height: 420px;
  }
}
