.testimonials {
  padding: 20px 0;
  overflow: hidden;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;

    h2 {
      font-size: 1.8rem;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
      margin-bottom: 30px;
    }

    .slider {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      .testimonial-card {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        padding: 30px 20px;
        max-width: 600px;
        width: 100%;
        margin: 0 15px;
        text-align: center;
        overflow: hidden;

        p {
          font-size: 18px;
          color: #333;
        }

        .testimonial-author {
          h3 {
            font-size: 22px;
            color: #222;
            margin-top: 20px;
          }

          p {
            font-size: 16px;
            color: #777;
          }
        }
      }

      .prev-btn,
      .next-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 2rem;
        cursor: pointer;
      }
      .prev-btn {
        left: 0;
      }

      .next-btn {
        right: 0;
      }
    }
  }
}
