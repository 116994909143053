.blog-post-content {
  background-color: #f9f9f9;
  .back-link {
    padding: 20px;
    display: flex;
    align-items: center;
    border: none;
    color: rgb(29, 121, 208);
    background-color: #dddddd;
    padding: 10px;
    h3 {
      margin-left: 5px;
      text-align: left;
      font-weight: normal;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    img {
      width: 100%;
      max-width: 600px;
      height: 360px;
      object-fit: cover;
      border-radius: 8px;
    }

    .post-details {
      margin-top: 20px;
      text-align: left;
      max-width: 600px;
      p {
        font-size: 16px;
        line-height: 1.5;
        color: #333;
      }
      .blog-details-header {
        h2 {
          font-size: 24px;
          margin-bottom: 10px;
        }

        .author {
          font-style: italic;
          color: #888;
          margin-bottom: 10px;
        }
      }

      .blog-details-content {
        p {
          margin: 30px 0;
        }
      }
    }
  }
}
