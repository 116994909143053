.volunteers-list {
  margin-bottom: 50px;
}
.volunteers-list .inner {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  padding: 0 10px;
}
.volunteers-list .inner .wrapper {
  display: flex;
  animation: scroll 30s linear infinite;
  animation-delay: 3s;
}
.volunteers-list .inner .volunteer-card {
  width: auto;
  min-width: 150px;
  margin-right: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.volunteers-list .inner .volunteer-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.volunteers-list .inner .volunteer-card .details {
  margin-top: 10px;
  text-align: center;
}
.volunteers-list .inner .volunteer-card .details h3 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}
.volunteers-list .inner .volunteer-card .details p {
  font-size: 14px;
  color: #666;
}

@keyframes scroll {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(calc(-100% - 20px));
  }
}
@media screen and (min-width: 1024px) {
  .volunteers-list .inner .volunteer-card {
    min-width: 250px;
  }
  .volunteers-list .inner .volunteer-card img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
}/*# sourceMappingURL=VolunteersList.css.map */