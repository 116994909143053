.terms-and-conditions {
  background-image: linear-gradient(359.8deg, #2b353f 2.2%, #222931 99.3%);
  padding: 50px 20px;
  .terms-and-conditions-container {
    max-width: 760px;
    margin: 0 auto;
    a {
      border-bottom: 1px solid #ffffff;
    }
    h1 {
      font-size: 2rem;
      margin-bottom: 20px;
      color: #fff;
    }

    h2 {
      font-size: 1.5rem;
      margin-top: 20px;
      margin-bottom: 10px;
      color: #f8f8f8;
    }

    p {
      margin-bottom: 10px;
      color: #cfcfcf;
    }

    ul {
      margin-bottom: 10px;
      padding-left: 20px;
    }

    li {
      color: #cfcfcf;
      margin-bottom: 5px;
      list-style: circle;
    }
    .address__section {
      color: #cfcfcf;
      margin: 10px 0;
      p {
        margin: 10px 0;
      }
      .phone,
      .email {
        color: #ff6a6a;
      }
    }
  }
}
