.contact__us__container {
  margin-bottom: 50px;
  .title {
    font-weight: bold;
    margin: 10px 0;
    font-size: 20px;
  }
  .contact__banner {
    background-color: #ff6a6a;
    color: #fff;
    padding: 20px;
    text-align: center;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
      color: #f8f8f8;
      line-height: 1.5;
      max-width: 600px;
      margin: 0 auto;
    }
  }
  .address__section {
    padding: 20px;
    color: #fff;
    margin: 30px 0;
    a {
      text-decoration: none;
    }
    .phone,
    .email {
      color: #ff6a6a;
    }
  }
  .contact__form {
    padding: 20px;
    h2 {
      color: #fff;
    }

    .form__group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px 0 10px;
      width: 100%;
      label {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        font-size: 18px;
        color: #f8f8f8;
        margin: 10px 0;
      }
      input[type='text'],
      input[type='number'],
      input[type='email'],
      input[type='tel'],
      select,
      textarea {
        margin-top: 5px;
        background-color: #e4e2e2;
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 8px;
        font-size: 16px;
        transition: border-color 0.3s ease;
        color: #000;
        &:focus {
          outline: none;
          border-color: #007bff;
        }
        &::placeholder {
          color: rgb(251, 98, 98);
        }
      }
      textarea {
        resize: vertical;
      }
    }
    .submit__btn {
      width: 100%;
      padding: 15px 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 0px;
      font-size: 18px;
      margin-top: 20px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

@media screen and (min-width: 584px) {
  .contact__us__container {
    .content-wrapper {
      max-width: 90%;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 784px) {
  .contact__us__container {
    .content-wrapper {
      max-width: 90%;
      display: grid;
      grid-template-columns: 30% 70%;
      gap: 20px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .contact__us__container {
    .content-wrapper {
      max-width: 700px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .contact__us__container {
    .content-wrapper {
      max-width: 760px;
    }
  }
}
