.home {
  position: relative;
  max-width: 100%;
}
.home .title-wrapper {
  padding: 10px;
  margin-bottom: 30px;
}
.home .title-wrapper h1 {
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
}
.home .container .call-to-action-btn {
  background-color: rgb(190, 24, 85);
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home .container .call-to-action-btn h1 {
  text-align: center;
  color: #fff;
  font-size: 20px;
}
.home .container .call-to-action-btn button {
  margin: 10px 0 0;
  padding: 0.75rem 1.5rem;
  border-radius: 4rem;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #fff;
  color: #fff;
}
.home .container .home-page-about-section {
  height: auto;
  position: relative;
}
.home .container .home-service-section-container {
  margin: 0;
  padding: 0;
  position: relative;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.home .container .home-service-section-container .services-section {
  z-index: 1;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  text-align: center;
  white-space: nowrap;
}
.home .container .volunteer-section-container {
  background: #2c3e4e;
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .container .volunteer-section-container .svg-before {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  fill: #27334e;
  transform: rotate(180deg);
}
.home .container .volunteer-section-container .content-wrapper {
  z-index: 1;
  max-width: 100%;
  overflow: hidden;
  text-align: center;
  padding: 50px 0;
}
.home .container .home-testimonials-section {
  background-image: linear-gradient(178.9deg, #b03969 5.1%, rgb(179, 101, 132) 109.3%);
  position: relative;
  height: 100%;
  padding: 6rem 0 3rem;
}
.home .container .home-testimonials-section .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home .container .home-testimonials-section .svg-before {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  fill: #27334e;
  transform: rotate(180deg);
}

@media screen and (min-width: 685px) {
  .home .container .home-service-section-container {
    height: 100%;
    padding: 20px 0 80px;
  }
  .home .container .home-service-section-container .services-section {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 95%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 785px) {
  .home .container .volunteer-section-container .content-wrapper {
    padding: 100px 0 50px;
  }
}
@media screen and (min-width: 1024px) {
  .home .container .home-service-section-container .services-section {
    width: 90%;
    gap: 20px;
  }
  .home .container .volunteer-section-container .content-wrapper {
    padding: 150px 0 50px;
  }
  .home .container .home-testimonials-section {
    padding: 9rem 0 6rem;
  }
}
@media screen and (min-width: 1200px) {
  .home .container .home-service-section-container .services-section {
    width: 90%;
    grid-template-columns: repeat(4, 1fr);
  }
}/*# sourceMappingURL=Home.css.map */