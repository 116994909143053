.faq-container {
  background-image: linear-gradient(359.8deg, #2b353f 2.2%, #222931 99.3%);
  color: #fff;
  padding: 50px 20px;

  .faq-item {
    margin-bottom: 30px;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #cfcfcf;
  }
}
