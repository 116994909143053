@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import 'variables';
.blog-post {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .post-img {
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

  .post-details {
    padding: 10px 20px 20px;

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
      color: #27334e;
    }

    .author {
      color: #888;
      margin-bottom: 10px;
    }

    p {
      color: #27334e;
      font-size: 16px;
      line-height: 1.6;
    }
  }
  .post-btn {
    padding: 20px;
    .read-more-btn {
      width: auto;
      padding: 15px;
      background-color: #f95050;
      color: $white-text;
      border: none;
      border-radius: 0;
      cursor: pointer;
      margin-top: 10px;
      transition: background-color 0.3s ease;
      font-size: 16px;
      &:hover {
        background-color: #eb1b24;
        color: $white-text;
      }
    }
  }
}

@media screen and (min-width: 784px) {
  .blog-post {
    img {
      height: 280px;
    }
  }
}
