.home-page-about {
  background-color: #f3f3f3;
  .wrapper {
    position: relative;
    background-color: #ff6a6a;
    max-width: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    .become-member-action-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .svg-before {
        position: absolute;
        top: 0;
        width: 100%;
        height: 160px;
        fill: #f3f3f3;
      }

      .hero-content {
        text-align: center;
        color: #fff;
        border-radius: 12px;
        font-size: 24px;
        padding: 20px 0;
        font-weight: 500;
        max-width: 600px;
        margin: 0 auto;
      }
      .btn-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 13rem 20px 50px;
      }

      h1 {
        text-align: center;
        color: #f8f8f8;
        font-weight: 500;
        font-size: 20px;
      }
      button {
        margin: 10px 0 0;
        padding: 0.75rem 1.5rem;
        border-radius: 4rem;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        cursor: pointer;
        border: none;
        outline: none;
        color: #fff;
        background-color: rgba(44, 62, 78, 1);
      }
    }
  }
  .container {
    position: relative;
    .showcase-call-to-action-btn {
      background-color: #f3f3f3;
      padding: 30px 0 0px;
      .header-wrapper {
        overflow: hidden;
      }
      .btn-link-container {
        display: grid;
        grid-template-columns: auto;
        gap: 20px;
        max-width: 100%;
        a {
          width: 100%;
          button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #f3f3f3;
            border-radius: 0;
            border-bottom: 4px solid #ff6a6a;
            width: 100%;
            color: #000;
            padding-left: 20px;
            cursor: pointer;
            svg {
              color: #ff6a6a;
              width: 3rem;
              height: 4rem;
            }
            &:hover {
              background-color: #27334e;
              color: #f8f8f8;
            }
          }
        }
      }
    }
    .mission-statement {
      padding: 50px 20px 20px;
      background-color: #f3f3f3;
      .mission-title {
        font-size: 1.7rem;
        color: #333;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        color: #666;
        line-height: 1.6;
        margin-bottom: 15px;
        font-size: 1rem;
      }
      a {
        color: #007bff;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .home-page-about {
    .container {
      .header-wrapper {
        max-width: 90%;
        margin: 0 auto;
      }
      .showcase-call-to-action-btn {
        .btn-link-container {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media screen and (min-width: 785px) {
  .home-page-about {
    .become-member-action-btn {
      min-height: 100%;
    }
    .container {
      max-width: 100%;
      margin: 0 auto;
      .header-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        max-width: 100%;
        padding: 0 20px;
      }
      .mission-statement {
        flex-basis: 60%;
      }
      .showcase-call-to-action-btn {
        .btn-link-container {
          grid-template-columns: repeat(1, 1fr);
          a {
            margin-top: auto;
            button {
              padding: 0 0 0 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .home-page-about {
    .container {
      .header-wrapper {
        // max-width: 90%;
      }
      .mission-statement {
        max-width: 90%;
        margin: 0 auto;
      }
      .showcase-call-to-action-btn {
        .btn-link-container {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .home-page-about {
    .become-member-btn {
      display: none;
    }
    .container {
      .mission-statement {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        max-width: 80%;
        margin: 0 auto;
        .red-bg {
          padding: 50px 30px;
        }
      }
    }
  }
}
