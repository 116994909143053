.testimonials {
  padding: 20px 0;
  overflow: hidden;
}
.testimonials .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
.testimonials .container h2 {
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  margin-bottom: 30px;
}
.testimonials .container .slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
.testimonials .container .slider .testimonial-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  max-width: 600px;
  width: 100%;
  margin: 0 15px;
  text-align: center;
  overflow: hidden;
}
.testimonials .container .slider .testimonial-card p {
  font-size: 18px;
  color: #333;
}
.testimonials .container .slider .testimonial-card .testimonial-author h3 {
  font-size: 22px;
  color: #222;
  margin-top: 20px;
}
.testimonials .container .slider .testimonial-card .testimonial-author p {
  font-size: 16px;
  color: #777;
}
.testimonials .container .slider .prev-btn,
.testimonials .container .slider .next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
}
.testimonials .container .slider .prev-btn {
  left: 0;
}
.testimonials .container .slider .next-btn {
  right: 0;
}/*# sourceMappingURL=Testimonials.css.map */