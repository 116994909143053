@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
a {
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

.services-section-comp {
  display: inline-block;
  width: 90%;
  height: 100%;
  padding: 0px 20px 20px;
  overflow: hidden;
  margin: 0 auto;
}
.services-section-comp .container {
  position: relative;
  max-width: 100%;
}
.services-section-comp .container .image {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.services-section-comp .container .image img {
  width: 100%;
  height: 280px;
  -o-object-fit: cover;
     object-fit: cover;
}
.services-section-comp .container .btn-link {
  outline: none;
}
.services-section-comp .container .btn-link .button {
  padding: 10px 0;
  width: 100%;
  height: auto;
  color: #f8f8f8;
  background-color: #007bff;
  border: none;
  text-align: center;
  font-size: 16px;
  border-radius: 0;
}
.services-section-comp .container .bullets {
  display: none;
}
@media (min-width: 768px) {
  .services-section-comp .container .bullets {
    display: block;
  }
  .services-section-comp .container .bullets ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 0;
  }
}

@media screen and (min-width: 500px) {
  .services-section-comp {
    width: 80%;
  }
  .services-section-comp .container .image img {
    height: 340px;
  }
}
@media screen and (min-width: 685px) {
  .services-section-comp {
    width: 95%;
    padding: 0;
    margin: 10px 0;
  }
  .services-section-comp .container .image img {
    height: 280px;
  }
}
@media screen and (min-width: 930px) {
  .services-section-comp {
    padding: 0;
    margin: 10px 0;
  }
  .services-section-comp .container .image img {
    height: 340px;
  }
}
@media screen and (min-width: 1200px) {
  .services-section-comp {
    width: 100%;
  }
  .services-section-comp .container .image img {
    height: 260px;
  }
}
@media screen and (min-width: 1400px) {
  .services-section-comp .container .image img {
    height: 300px;
  }
}/*# sourceMappingURL=ServicesSection.css.map */