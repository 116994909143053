.subscribe-to-blog {
  padding: 10px 20px;
  margin-top: 20px;
}
.subscribe-to-blog h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
}
.subscribe-to-blog p {
  color: #d5d5d5;
  font-size: 16px;
}
.subscribe-to-blog form {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
}
.subscribe-to-blog form label {
  width: 100%;
}
.subscribe-to-blog form input[type=email] {
  width: 100%;
  flex: 1;
  padding: 15px 20px;
  border: 0;
  border-radius: 0;
  outline: none;
  font-size: 16px;
}
.subscribe-to-blog form button {
  width: auto;
  padding: 15px 20px;
  background-color: #f95050;
  color: #fff;
  border: none;
  border-radius: 0;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.subscribe-to-blog form button:hover {
  background-color: #eb1b24;
}/*# sourceMappingURL=SubscribeToBlog.css.map */