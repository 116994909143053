.nav-open {
  overflow: hidden;
}

.sticky {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.nav {
  background-color: #27334e;
}
.nav .container {
  position: relative;
  z-index: 900;
}
.nav .container .logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
}
.nav .container .logo .menu__icon {
  padding: 0px 20px 0px 0px;
}
.nav .container .logo .menu__icon .icon {
  font-size: 3rem;
  cursor: pointer;
  color: #f8f8f8;
}
.nav .container .logo a {
  display: flex;
  align-items: center;
}
.nav .container .logo a .img-wrapper {
  padding-top: 15px;
}
.nav .container .logo a .img-wrapper img {
  width: 180px;
  height: auto;
}
.nav .container .showMenu {
  display: block !important;
}
.nav .container .menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #27334e;
  width: 100%;
  height: 100vh;
  overflow-y: scroll; /* Added to enable vertical scrolling */
}
.nav .container .menu .menu-container {
  position: relative;
}
.nav .container .menu .menu-container .modal-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav .container .menu .menu-container .modal-logo a .img-wrapper {
  padding-top: 15px;
}
.nav .container .menu .menu-container .modal-logo a .img-wrapper img {
  width: 180px;
  height: auto;
}
.nav .container .menu .menu-container .modal-logo .icon__div {
  text-align: right;
  padding: 0 10px;
}
.nav .container .menu .menu-container .modal-logo .icon__div .icon {
  font-size: 46px;
  cursor: pointer;
  color: #f8f8f8;
}
.nav .container .menu .menu-container .main-menu-list li {
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 10px 20px 0;
  list-style: none;
  font-family: "rustica";
  margin: 15px 0 0;
  cursor: pointer;
  color: #fff;
}
.nav .container .menu .menu-container .main-menu-list a {
  display: block;
  width: 100%;
}
.nav .container .menu .menu-container .main-menu-list .sub__menu li {
  display: flex;
  justify-content: start;
  align-items: center;
  color: #f8f8f8;
}
.nav .container .menu .menu-container .main-menu-list .sub__menu li .icon {
  margin-right: 10px;
  color: #ffbd59;
}
.nav .container .menu .menu-container .main-menu-list .underline__services__wrapper {
  border-bottom: 1px solid #418fdd;
  width: 100%;
}
.nav .container .menu .menu-container .main-menu-list .services__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav .container .menu .menu-container .main-menu-list .services__wrapper a {
  width: 100%;
}
.nav .container .menu .menu-container .main-menu-list .services__wrapper span {
  width: 100%;
}
.nav .container .menu .menu-container .main-menu-list .services__wrapper .active__icon {
  color: #418fdd !important;
}
.nav .container .menu .menu-container .nav-call-to-action-btn {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  align-items: center;
  margin: 20px 0;
  padding: 20px 10px;
}
.nav .container .menu .menu-container .nav-call-to-action-btn button {
  color: #fff;
  padding: 30px;
  cursor: pointer;
}
.nav .container .menu .menu-container .nav-call-to-action-btn a {
  text-align: right;
}
.nav .container .menu .menu-container .nav-call-to-action-btn .member-btn-link {
  background-image: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);
}
.nav .container .menu .menu-container .nav-call-to-action-btn .partner-btn-link {
  background-image: linear-gradient(-225deg, #22e1ff 0%, #1d8fe1 48%, #625eb1 100%);
}
.nav .container .menu .menu-container .nav-call-to-action-btn .volunteer-btn-link {
  background-image: linear-gradient(-20deg, #ffbd59 0%, #ff9a44 100%);
}

@media screen and (min-width: 984px) {
  .nav .container {
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .nav .container .logo {
    padding: 0;
    width: auto;
    flex-shrink: 0;
    display: inline-block;
  }
  .nav .container .logo a .img-wrapper {
    padding-top: 15px;
  }
  .nav .container .logo .menu__icon {
    display: none;
  }
  .nav .container .menu {
    display: none;
  }
  .nav .container .large-screen-menu-wrapper {
    flex-grow: 1;
  }
}
@media screen and (max-width: 984px) {
  .nav .container .large-screen-menu {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .nav .container {
    max-width: 95%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1200px) {
  .nav .container {
    max-width: 85%;
    margin: 0 auto;
  }
}/*# sourceMappingURL=Nav.css.map */