@media screen and (min-width: 984px) {
  .large-screen-menu {
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: 100%;
    z-index: 100;
  }
  .large-screen-menu .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #27334e;
    opacity: 0.1;
  }
  .large-screen-menu .menu-container {
    padding-right: 30px;
  }
  .large-screen-menu .menu-container .modal-logo {
    display: none;
  }
  .large-screen-menu .menu-container .main-menu-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  .large-screen-menu .menu-container .main-menu-list li {
    position: relative;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
    padding: 0px 20px;
    list-style: none;
    font-family: "rustica";
    cursor: pointer;
    color: #fff;
  }
  .large-screen-menu .menu-container .main-menu-list li .sub__menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #27334e;
    padding: 10px 20px 10px 10px;
    margin-top: 10px;
  }
  .large-screen-menu .menu-container .main-menu-list li .sub__menu li {
    color: #f8f8f8;
    padding: 10px 0;
    width: 100%;
  }
  .large-screen-menu .menu-container .main-menu-list li .sub__menu li a {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
  }
  .large-screen-menu .menu-container .main-menu-list li .sub__menu li a .icon {
    margin-right: 10px;
    color: #ffbd59;
  }
  .large-screen-menu .menu-container .main-menu-list li .sub__menu li a span {
    width: 100%;
  }
  .large-screen-menu .menu-container .main-menu-list .services-li .services-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .large-screen-menu .menu-container .main-menu-list .services-li .services-wrapper .icon {
    background-color: #151c2c;
    margin-left: 5px;
    color: #ffbd59;
  }
  .large-screen-menu .menu-container .main-menu-list .underline__services__wrapper {
    border: 0;
  }
  .large-screen-menu .menu-container .nav-call-to-action-btn {
    display: none;
  }
}/*# sourceMappingURL=LargeScreenNav.css.map */