.blog-post-content {
  background-color: #f9f9f9;
}
.blog-post-content .back-link {
  padding: 20px;
  display: flex;
  align-items: center;
  border: none;
  color: rgb(29, 121, 208);
  background-color: #dddddd;
  padding: 10px;
}
.blog-post-content .back-link h3 {
  margin-left: 5px;
  text-align: left;
  font-weight: normal;
}
.blog-post-content .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
.blog-post-content .container img {
  width: 100%;
  max-width: 600px;
  height: 360px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
}
.blog-post-content .container .post-details {
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
}
.blog-post-content .container .post-details p {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}
.blog-post-content .container .post-details .blog-details-header h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
.blog-post-content .container .post-details .blog-details-header .author {
  font-style: italic;
  color: #888;
  margin-bottom: 10px;
}
.blog-post-content .container .post-details .blog-details-content p {
  margin: 30px 0;
}/*# sourceMappingURL=BlogPostPage.css.map */