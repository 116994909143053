.form-page {
  max-width: 100%;
  margin: 0 auto;
  padding: 50px 20px 100px;
  text-align: center;
  background-color: #222931;
  border-radius: 0px;

  .intro-section {
    margin-bottom: 30px;
    h1 {
      font-size: 24px;
      margin-bottom: 20px;
      color: #f8f8f8;
    }

    p {
      font-size: 16px;
      color: #f8f8f8;
      line-height: 1.5;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .form-content-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      width: 100%;
    }
    .lrg-textarea {
      margin-top: 20px;
    }
    .terms-checkbox-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      a {
        color: #449bf8;
        text-decoration: underline;
      }
    }
    label {
      text-align: left;
      font-size: 18px;
      color: #fff;
      margin: 10px 0;
      width: 100%;
    }
    .postcode-lookup {
      .container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        input {
          border-radius: 0;
        }
        .lookup-btn {
          flex: 1;
          margin: 10px 0 0;
          border-radius: 0;
          width: 50%;
          padding: 12px;
          background-color: #007bff;
          color: #fff;
          border: none;
          font-size: 18px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #0056b3;
          }
        }
      }
      .postcode-error {
        color: #fb5959;
        padding: 10px;
      }
      .content-data {
        margin: 20px 0 0;
        background-color: rgb(44, 62, 78);
        border-radius: 8px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          margin: 10px 0;
        }
      }
    }
    .checkbox-container {
      .checkbox-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #f8f8f8;
        padding: 10px 0;
      }
    }

    .radio-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        gap: 15px;
        color: #f8f8f8;
      }
    }

    input[type='radio'],
    input[type='checkbox'] {
      width: 20px; /* Adjust width as needed */
      height: 20px; /* Adjust height as needed */
      cursor: pointer;
    }

    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='tel'],
    select,
    textarea {
      margin-top: 10px;
      background-color: #e4e2e2;
      width: 100%;
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 8px;
      font-size: 16px;
      transition: border-color 0.3s ease;
      color: #000;
      &:focus {
        outline: none;
        border-color: #007bff;
      }
      &::placeholder {
        color: rgb(251, 98, 98);
      }
    }
    textarea {
      resize: vertical;
    }

    .submit-button {
      margin: 20px 0 0;
      width: 100%;
      padding: 15px 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 0px;
      font-size: 18px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

@media screen and (min-width: 584px) {
  .form-page {
    form {
      max-width: 90%;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 784px) {
  .form-page {
    form {
      max-width: 80%;
      .form-content-wrapper {
        grid-template-columns: repeat(2, 1fr);
        margin: 0 auto;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .form-page {
    form {
      max-width: 700px;
      .form-content-wrapper {
        gap: 30px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .form-page {
    form {
      max-width: 760px;
      .form-content-wrapper {
        gap: 50px;
      }
    }
  }
}
