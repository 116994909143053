@import 'variables';

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  background-color: #27334e;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

img,
video {
  max-width: 100%;
  height: auto;
}

img,
svg,
video {
  display: inline-block;
  vertical-align: middle;
}

span,
label,
select,
textarea,
input,
li {
  font-family: 'Roboto', sans-serif;
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  color: #fff;
  padding: 20px 10px;
  text-align: center;
  z-index: 999;

  p {
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    margin: 0 auto;
    max-width: 600px;
    button {
      width: 100%;
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 7px 15px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin: 10px 0;
      border-radius: 8px;

      &.accept-btn {
        background-color: #007bff;

        &:hover {
          background-color: #0056b3;
        }
      }

      &.reject-btn {
        background-color: #fc2a3b;

        &:hover {
          background-color: #b61e2b;
        }
      }
    }
  }
}

/* Error Component */
.success,
.error {
  font-size: 1.2rem;
  background-color: #3b4755;
  padding: 20px;
  width: 100%;
  margin: 30px 0;
  text-align: center;
}

.error {
  color: #fb5959; /* Red color for error messages */
}

.success {
  color: #39cc39; /* Green color for success messages */
}
