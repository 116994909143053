.main__header {
  background-color: #171613;
  display: none;
  .container {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    .contact {
      p {
        font-family: Arial, Helvetica, sans-serif;
        color: #a1a1a1;
        font-size: 14px;
      }
    }
    .socials {
      .icon {
        color: #718191;
        font-size: 32px;
      }
    }
  }
}
