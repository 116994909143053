.coming-soon-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.coming-soon-container .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff6a6a;
  opacity: 0.5;
}
.coming-soon-container .content-wrapper {
  max-width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 30px;
  border-radius: 10px;
  background-color: #222931;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}
.coming-soon-container .content-wrapper .title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #fff;
}
.coming-soon-container .content-wrapper p {
  font-size: 1.2rem;
  color: #f8f8f8;
  margin: 10px 0;
}
.coming-soon-container .content-wrapper button {
  margin: 10px 0 0;
  padding: 0.75rem 1.5rem;
  border-radius: 4rem;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  outline: none;
  color: #fff;
  background-color: rgb(44, 62, 78);
}/*# sourceMappingURL=ComingSoon.css.map */