@import 'variables';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

.services__content {
  overflow: hidden;
  position: relative;
  padding: 0 20px;
  max-width: 100%;
  height: 100%;
  .grid-container {
    .grid-content {
      padding: 20px 10px;
      max-width: 100%;
      margin: 20px 0;
      border-radius: 20px;
      background-color: rgb(44, 62, 78);
      p {
        color: #f8f8f8;
        max-width: 600px;
        margin: 0 0 10px;
        line-height: 1.5rem;
        font-size: 16px;
      }
      a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        background-color: #f95050;
        svg {
          font-size: 16px;
          color: #fff;
        }
        .button {
          width: auto;
          color: $white-text;
          border: none;
          border-radius: 0;
          cursor: pointer;
          transition: background-color 0.3s ease;
          font-size: 16px;
          &:hover {
            background-color: #eb1b24;
            color: $white-text;
          }
        }
        &:hover {
          background-color: #eb1b24;
          color: $white-text;
        }
      }
    }
    .grid-collection {
      position: relative;
      video {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border: none;
        outline: none;
        width: 100%;
        height: 260px;
        object-fit: cover;
      }
      .wrapper {
        overflow: hidden;
        transition: box-shadow 0.3s ease;
        padding: 3rem 20px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        .content-section {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          height: 100%;
          h2 {
            font-size: 1.7rem;
            margin-bottom: 8px;
            text-transform: uppercase;
            color: $white-text;
            font-weight: bold;
          }
          p {
            margin-bottom: 16px;
            color: #f8f8f8;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 784px) {
}

@media screen and (min-width: 1024px) {
  .services__content {
    .grid-container {
      .grid-collection {
        video {
          height: 300px;
        }
        .wrapper {
          .content-section {
            max-width: 80%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .services__content {
    .grid-container {
      .grid-collection {
        video {
          height: 320px;
        }
      }
    }
  }
}
