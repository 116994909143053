.executives-team-members {
  background-image: radial-gradient(
    circle 400px at 6.8% 8.3%,
    rgba(255, 244, 169, 1) 0%,
    rgba(255, 244, 234, 1) 100.2%
  );
}
.management-team-members {
  background-image: radial-gradient(
    circle 400px at 6.8% 8.3%,
    rgb(255, 169, 170) 0%,
    rgba(255, 244, 234, 1) 100.2%
  );
}
.team-members {
  .title-wrapper {
    background-color: #e6e6e6dd;
    border-radius: 0px;
    padding: 10px 20px;
    max-width: 100%;
    margin: 0 auto;
    .title-section {
      color: #27334e;
      font-size: 22px;
      text-align: center;
    }
  }
  ul {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    padding: 30px 10px;
    max-width: 90%;
    margin: 0 auto;
    li {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      img {
        width: 100%;
        height: auto;
        border-radius: 50%;
      }
      .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        span {
          text-transform: capitalize;
          text-align: center;
          font-size: 16px;
          text-transform: capitalize;
        }
        .name {
          font-weight: bold;
        }
      }
      .btn-div {
        .bio-btn {
          width: auto;
          padding: 5px 15px;
          margin-top: 10px;
          background-color: #3f51b5;
          color: #fff;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          font-size: 1rem;
          &:hover {
            background-color: #2e3d93;
            color: #fff;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .team-members {
    ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (min-width: 784px) {
  .team-members {
    ul {
      grid-template-columns: repeat(3, 1fr);
      max-width: 85%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .team-members {
    ul {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media screen and (min-width: 1200px) {
  .team-members {
    ul {
      grid-template-columns: repeat(5, 1fr);
      max-width: 80%;
    }
  }
}
