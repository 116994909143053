@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import 'variables';

.course-card {
  background-color: #ffffff;
  border-radius: 0px;
  box-shadow: 0 6px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .course-image {
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

  .course-header {
    padding: 15px;

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
      color: #27334e;
      text-transform: capitalize;
    }
    p {
      margin-top: 15px;
      color: #333;
      font-size: 16px;
      line-height: 1.6;
    }
  }
  .course-details {
    padding: 15px;
    .explore-more-btn-link {
      .explore-courses-btn {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 15px;
        margin: 20px 0;
        background-color: #ff6a6a;
        color: $white-text;
        border: none;
        border-radius: 0px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: 16px;
        &:hover {
          background-color: #eb1b24;
          color: $white-text;
        }
        svg {
          padding-left: 7px;
          font-size: 28px;
        }
      }
    }
    .line-break {
      width: 100%;
      height: 3px;
      margin: 0 auto;
      background-color: #ff6a6a;
      border-radius: 0;
    }
    .event-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 600px;
      margin: 20px auto 0;
      .event-content {
        text-align: center;
        margin: 10px 0;
        span {
          font-size: 16px;
          padding: 0 5px;
          color: #000;
          font-weight: 500;
          text-transform: capitalize;
        }

        svg {
          font-size: 18px;
          color: #6c6c6c;
        }
      }
    }
  }
}

@media screen and (min-width: 784px) {
  .course-card {
    .course-image {
      img {
        height: 280px;
      }
    }
  }
}
