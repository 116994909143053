.events-services {
  margin-top: 50px;
  .sub-header {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      text-align: center;
      color: #fff;
      font-weight: 400;
      font-size: 32px;
    }
    p {
      padding-top: 10px;
      text-align: center;
      max-width: 600px;
      color: rgb(208, 208, 208);
    }
  }
  .content {
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin: 0 auto;
      overflow: hidden;
      li {
        display: flex;
        justify-content: space-between;
        align-items: start;
        gap: 20px;
        position: relative;
        .icon-div {
          display: flex;
          flex-direction: column;
          align-items: center;
          .line {
            position: absolute;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: #fff;
          }
          .icon-wrapper {
            position: relative;
            display: flex;
            padding: 1rem;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #213232;
            color: #00c99f;
            z-index: 1;
            .icon {
              font-size: 32px;
            }
          }
        }
        .content-wrapper {
          display: flex;
          flex-direction: column;
          align-items: start;
          padding: 10px 0;
          .content-title {
            color: #fff;
            font-weight: 400;
            font-size: 18px;
          }
          .content-desc {
            margin-top: 10px;
            color: rgb(208, 208, 208);
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 785px) {
  .events-services {
    .content {
      ul {
        flex-direction: row;
        li {
          flex-basis: 50%;
          padding: 0 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .events-services {
    .content {
      ul {
        max-width: 85%;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
}
