.cookie-policy {
  background-image: linear-gradient(359.8deg, #2b353f 2.2%, #222931 99.3%);
  padding: 50px 20px;
}
.cookie-policy .cookie-container {
  max-width: 760px;
  margin: 0 auto;
}
.cookie-policy .cookie-container a {
  border-bottom: 1px solid #ffffff;
}
.cookie-policy .cookie-container h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 500;
}
.cookie-policy .cookie-container h2 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #f8f8f8;
}
.cookie-policy .cookie-container p {
  color: #cfcfcf;
  font-size: 16px;
  margin-bottom: 20px;
}
.cookie-policy .cookie-container p strong {
  font-weight: bold;
}
.cookie-policy .cookie-container p br {
  display: block;
  margin: 5px 0;
  content: "";
}/*# sourceMappingURL=CookiePolicy.css.map */