.terms-and-conditions {
  background-image: linear-gradient(359.8deg, #2b353f 2.2%, #222931 99.3%);
  padding: 50px 20px;
}
.terms-and-conditions .terms-and-conditions-container {
  max-width: 760px;
  margin: 0 auto;
}
.terms-and-conditions .terms-and-conditions-container a {
  border-bottom: 1px solid #ffffff;
}
.terms-and-conditions .terms-and-conditions-container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #fff;
}
.terms-and-conditions .terms-and-conditions-container h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #f8f8f8;
}
.terms-and-conditions .terms-and-conditions-container p {
  margin-bottom: 10px;
  color: #cfcfcf;
}
.terms-and-conditions .terms-and-conditions-container ul {
  margin-bottom: 10px;
  padding-left: 20px;
}
.terms-and-conditions .terms-and-conditions-container li {
  color: #cfcfcf;
  margin-bottom: 5px;
  list-style: circle;
}
.terms-and-conditions .terms-and-conditions-container .address__section {
  color: #cfcfcf;
  margin: 10px 0;
}
.terms-and-conditions .terms-and-conditions-container .address__section p {
  margin: 10px 0;
}
.terms-and-conditions .terms-and-conditions-container .address__section .phone,
.terms-and-conditions .terms-and-conditions-container .address__section .email {
  color: #ff6a6a;
}/*# sourceMappingURL=TermsAndConditions.css.map */