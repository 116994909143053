// SubscribeToBlog.scss

.subscribe-to-blog {
  padding: 10px 20px;
  margin-top: 20px;
  h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #fff;
  }
  p {
    color: #d5d5d5;
    font-size: 16px;
  }
  form {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
    label {
      width: 100%;
    }
    input[type='email'] {
      width: 100%;
      flex: 1;
      padding: 15px 20px;
      border: 0;
      border-radius: 0;
      outline: none;
      font-size: 16px;
    }

    button {
      width: auto;
      padding: 15px 20px;
      background-color: #f95050;
      color: #fff;
      border: none;
      border-radius: 0;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #eb1b24;
      }
    }
  }
}
