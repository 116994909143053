.contact__us__container {
  margin-bottom: 50px;
}
.contact__us__container .title {
  font-weight: bold;
  margin: 10px 0;
  font-size: 20px;
}
.contact__us__container .contact__banner {
  background-color: #ff6a6a;
  color: #fff;
  padding: 20px;
  text-align: center;
}
.contact__us__container .contact__banner h2 {
  font-size: 24px;
}
.contact__us__container .contact__banner p {
  font-size: 16px;
  color: #f8f8f8;
  line-height: 1.5;
  max-width: 600px;
  margin: 0 auto;
}
.contact__us__container .address__section {
  padding: 20px;
  color: #fff;
  margin: 30px 0;
}
.contact__us__container .address__section a {
  text-decoration: none;
}
.contact__us__container .address__section .phone,
.contact__us__container .address__section .email {
  color: #ff6a6a;
}
.contact__us__container .contact__form {
  padding: 20px;
}
.contact__us__container .contact__form h2 {
  color: #fff;
}
.contact__us__container .contact__form .form__group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0 10px;
  width: 100%;
}
.contact__us__container .contact__form .form__group label {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  font-size: 18px;
  color: #f8f8f8;
  margin: 10px 0;
}
.contact__us__container .contact__form .form__group input[type=text],
.contact__us__container .contact__form .form__group input[type=number],
.contact__us__container .contact__form .form__group input[type=email],
.contact__us__container .contact__form .form__group input[type=tel],
.contact__us__container .contact__form .form__group select,
.contact__us__container .contact__form .form__group textarea {
  margin-top: 5px;
  background-color: #e4e2e2;
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  color: #000;
}
.contact__us__container .contact__form .form__group input[type=text]:focus,
.contact__us__container .contact__form .form__group input[type=number]:focus,
.contact__us__container .contact__form .form__group input[type=email]:focus,
.contact__us__container .contact__form .form__group input[type=tel]:focus,
.contact__us__container .contact__form .form__group select:focus,
.contact__us__container .contact__form .form__group textarea:focus {
  outline: none;
  border-color: #007bff;
}
.contact__us__container .contact__form .form__group input[type=text]::-moz-placeholder, .contact__us__container .contact__form .form__group input[type=number]::-moz-placeholder, .contact__us__container .contact__form .form__group input[type=email]::-moz-placeholder, .contact__us__container .contact__form .form__group input[type=tel]::-moz-placeholder, .contact__us__container .contact__form .form__group select::-moz-placeholder, .contact__us__container .contact__form .form__group textarea::-moz-placeholder {
  color: rgb(251, 98, 98);
}
.contact__us__container .contact__form .form__group input[type=text]::placeholder,
.contact__us__container .contact__form .form__group input[type=number]::placeholder,
.contact__us__container .contact__form .form__group input[type=email]::placeholder,
.contact__us__container .contact__form .form__group input[type=tel]::placeholder,
.contact__us__container .contact__form .form__group select::placeholder,
.contact__us__container .contact__form .form__group textarea::placeholder {
  color: rgb(251, 98, 98);
}
.contact__us__container .contact__form .form__group textarea {
  resize: vertical;
}
.contact__us__container .contact__form .submit__btn {
  width: 100%;
  padding: 15px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 0px;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.contact__us__container .contact__form .submit__btn:hover {
  background-color: #0056b3;
}

@media screen and (min-width: 584px) {
  .contact__us__container .content-wrapper {
    max-width: 90%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 784px) {
  .contact__us__container .content-wrapper {
    max-width: 90%;
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .contact__us__container .content-wrapper {
    max-width: 700px;
  }
}
@media screen and (min-width: 1200px) {
  .contact__us__container .content-wrapper {
    max-width: 760px;
  }
}/*# sourceMappingURL=ContactUs.css.map */