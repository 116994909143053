// App Background
$app-background: #222931;

// Logo Title
$logo-title: #f8f8f8;

// Nav Menu Icon
$nav-menu-icon: #f8f8f8;

// Any normal text
$white-text: #f8f8f8;

// Darker shade of white-color
$shaded-white: #f5f5f5;
