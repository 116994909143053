@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;400&display=swap');

.footer {
  background-color: #171613;
  border-top: 1px solid rgb(82, 78, 78);
  .container {
    .logo {
      margin-top: 30px;
      display: flex;
      align-items: center;
      color: #fff;
      a {
        img {
          width: 210px;
          height: auto;
        }
      }
    }

    .content {
      margin-top: 20px;
      padding: 0 20px;
      h3 {
        margin-bottom: 20px;
        color: #fff;
        font-family: 'Jost', sans-serif;
        text-transform: capitalize;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          margin-bottom: 10px;
          color: rgb(161, 161, 161);
          text-transform: capitalize;
        }
      }
    }
    .copyright {
      margin-top: 30px;
      border-top: 1px solid grey;
      p {
        color: rgb(141, 141, 141);
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        text-align: center;
        padding: 10px;
      }
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}

// media query
@media screen and (min-width: 580px) {
  .footer {
    .container {
      padding: 10px 0px;
      width: 90%;
      margin: 0 auto;
      .group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-gap: 0 20px;
      }
    }
  }
}

@media screen and (min-width: 785px) {
  .footer {
    border: none;
  }
}

@media screen and (min-width: 992px) {
  .footer {
    .container {
      width: 85%;
      .group {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
