.our__story {
  position: relative;
  margin: 50px 0;
}
.our__story h1,
.our__story h2,
.our__story h3,
.our__story h4 {
  color: #fff;
}
.our__story .container {
  position: relative;
}
.our__story .container .cic {
  font-size: 22px;
  color: #e8963f;
}
.our__story .container .svg-before {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  fill: #3f4f76;
}
.our__story .container .content-wrapper .content {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.our__story .container .content-wrapper .content .title {
  text-align: left;
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 1px;
  text-transform: capitalize;
  width: 100%;
  font-size: 1.4rem;
  margin: 20px 0 10px;
}
.our__story .container .content-wrapper .content p {
  color: #f8f8f8;
  font-size: 16px;
  margin: 10px 0;
  line-height: 1.4rem;
}
.our__story .container .content-wrapper .content ul {
  margin: 10px 0;
  padding-left: 20px;
}
.our__story .container .content-wrapper .content ul li {
  color: #cfcfcf;
  margin-bottom: 5px;
  list-style: circle;
  font-size: 16px;
  line-height: 1.4rem;
}

@media screen and (min-width: 784px) {
  .our__story .container .content-wrapper {
    overflow: hidden;
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
    gap: 20px;
    max-width: 90%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1024px) {
  .our__story .container .content-wrapper {
    max-width: 80%;
  }
}
@media screen and (min-width: 1200px) {
  .our__story .container .content-wrapper {
    max-width: 60%;
  }
}/*# sourceMappingURL=OurStory.css.map */