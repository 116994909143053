.sports {
  background-color: #222931;
}
.sports .call-to-action-btn {
  background-color: #343e4a;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sports .call-to-action-btn h1 {
  text-align: center;
  color: #fff;
  font-size: 20px;
  max-width: 600px;
  margin: 0 auto;
}
.sports .call-to-action-btn button {
  margin: 10px 0 0;
  padding: 0.75rem 1.5rem;
  border-radius: 4rem;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #778eb6;
  color: #fff;
}
.sports .sports-container {
  padding: 20px 20px 80px;
  overflow: hidden;
  max-width: 100%;
}
.sports .sports-container .main-header {
  margin: 30px 0;
  width: 100%;
}
.sports .sports-container .main-header .wrapper {
  margin: 10px 0;
}
.sports .sports-container .main-header .wrapper .main-header-content {
  max-width: 600px;
}
.sports .sports-container .main-header .wrapper .main-header-content .title {
  color: #fff;
  font-weight: 400;
  font-size: 32px;
}
.sports .sports-container .main-header .wrapper .main-header-content p {
  font-size: 16px;
  padding-top: 10px;
  color: #d0d0d0;
}
.sports .sports-container .main-header .wrapper .btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  max-width: 100%;
}
.sports .sports-container .main-header .wrapper .btn button {
  width: 100%;
  margin: 20px 0 0;
  padding: 0.75rem 1.5rem;
  border-radius: 4rem;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #2e466f;
}
.sports .sports-container .main-header .wrapper .btn .learn-btn {
  color: #fff;
  background-color: #2e466f;
}
.sports .sports-container .main-header .wrapper .btn .teach-btn {
  color: #fff;
}
.sports .sports-container .image {
  margin-top: 30px;
  max-width: 100%;
  height: 100%;
}
.sports .sports-container .image img {
  border-radius: 20px;
  border: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 560px) {
  .sports .sports-container {
    max-width: 90%;
    margin: 30px auto 0;
    padding: 20px 0 85px;
  }
}
@media screen and (min-width: 785px) {
  .sports .sports-container .main-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .sports .sports-container .main-header .image {
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
  .sports .sports-container {
    max-width: 90%;
  }
  .sports .sports-container .main-header .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .sports .sports-container .main-header .wrapper .main-header-content .title {
    font-size: 36px;
  }
  .sports .sports-container .main-header .wrapper .main-header-content p {
    font-size: 18px;
  }
  .sports .sports-container .main-header .wrapper .btn button {
    margin: 0;
    font-size: 18px;
  }
  .sports .sports-container .main-header .image {
    height: 380px;
  }
  .sports .sports-container .main-header .image img {
    border-radius: 50px;
  }
}
@media screen and (min-width: 1200px) {
  .sports .sports-container {
    max-width: 85%;
  }
  .sports .sports-container .main-header .wrapper {
    flex-direction: column;
    align-items: flex-start;
    max-width: 500px;
    margin: 10px auto;
  }
  .sports .sports-container .main-header .wrapper .btn button {
    margin: 20px 0 0;
  }
  .sports .sports-container .main-header .image {
    height: 100%;
  }
}/*# sourceMappingURL=Sports.css.map */