@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;400&display=swap');
.newsletter {
  background-color: #171613;
  grid-area: nsl;
  height: 100%;
  .container {
    padding: 50px 15px 10px;
    z-index: 950;

    .title {
      color: #fff;
      font-family: 'Jost', sans-serif;
      h2 {
        text-transform: capitalize;
      }
      p {
        color: #f8f8f8;
        margin-top: 5px;
      }
      .newsletter-color {
        text-transform: capitalize;
        color: #ff6a6a;
      }
    }

    .input {
      margin: 20px 0;
      form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        input {
          font-size: 16px;
          padding: 15px;
          border: none;
          outline: none;
          border-radius: 0px;
        }
        button {
          font-size: 16px;
          width: 100%;
          background-color: #ff6a6a;
          color: #fff;
          padding: 15px;
          margin-top: 10px;
          border-radius: 0px;
        }
      }
    }
    .socials {
      margin: 30px 0;
      .icon {
        color: #718191;
        font-size: 32px;
        margin-right: 10px;
      }
    }
  }
}

// media query

@media screen and (min-width: 580px) {
  .newsletter {
    .container {
      width: 90%;
      margin: 0 auto;
    }
  }
}
