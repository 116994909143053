@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import 'variables';

h1,
h2,
h3 {
  font-family: 'Noto Sans', sans-serif;
}

.services {
  position: relative;
  .call-to-action-btn {
    background-color: rgb(165, 90, 240);
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      text-align: center;
      color: $white-text;
      font-size: 20px;
    }
    button {
      margin: 10px 0 0;
      padding: 0.75rem 1.5rem;
      border-radius: 4rem;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      border: 1px solid #fff;
      color: #fff;
    }
  }
  .services-container {
    margin-bottom: 80px;
    .service-banner {
      position: relative;
      .overlay {
        background-image: linear-gradient(
          359.8deg,
          #3b4755 2.2%,
          #222931 99.3%
        );

        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        opacity: 0.8;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        .title {
          font-size: 1.8rem;
          text-transform: uppercase;
          font-weight: bold;
          color: $white-text;
          text-align: center;
        }
      }
    }
    .services-intro {
      margin: 50px auto 100px;
      p {
        padding: 10px 20px;
        color: $white-text;
        text-align: left;
        opacity: 0.8;
        font-size: 18px;
        line-height: 1.5rem;
      }
      .arrow-down {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0 50px;
        svg {
          background-color: #a55af0;
          border-radius: 50%;
          color: #fff;
        }
      }
    }
    .services-content-wrapper {
      .services__content {
        margin: 50px auto;
      }
    }
  }
}

@media screen and (min-width: 784px) {
  .services {
    .services-container {
      .services-intro {
        max-width: 80%;
      }
      .services-content-wrapper {
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
        gap: 20px;
        max-width: 100%;
        margin: 0 auto;
        .services__content {
          &:nth-child(2) {
            margin-top: 240px;
          }
          &:nth-child(4) {
            margin-top: 240px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .services {
    .services-container {
      .services-intro {
        max-width: 70%;
      }
      .services-content-wrapper {
        max-width: 90%;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .services {
    .services-container {
      .services-intro {
        max-width: 50%;
      }
      .services-content-wrapper {
        max-width: 80%;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .services {
    .services-container {
      .services-content-wrapper {
        max-width: 75%;
      }
    }
  }
}
