@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
h1,
h2,
h3 {
  font-family: "Noto Sans", sans-serif;
}

p {
  font-family: "Roboto", sans-serif;
}

.showcase-loading-screen {
  max-width: 100%;
  height: 100vh;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.showcase-loading-screen {
  display: inline-block;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showcase-loading-screen:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #f8f8f8 transparent #f8f8f8 transparent;
  animation: loading 1.2s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.banner {
  position: relative;
  max-width: 100%;
  background: linear-gradient(90deg, hsl(0, 86%, 67%) 0%, #ff6a6a 100%);
  height: auto;
  overflow: hidden;
}
.banner .banner-container {
  position: relative;
  width: 100%;
  min-height: 145vh;
}
.banner .banner-container .container-img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto;
}
.banner .banner-container .container-img img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.banner .banner-container .banner-content {
  position: relative;
  max-width: 100%;
  height: 100%;
}
.banner .banner-container .banner-content .showcase-header {
  max-width: 100%;
  position: relative;
  height: auto;
  overflow: hidden;
  padding-top: 12rem;
  padding-left: 0;
}
.banner .banner-container .banner-content .showcase-header .title-header .links-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
}
.banner .banner-container .banner-content .showcase-header .title-header .links-wrapper a {
  display: flex;
  align-items: center;
  background-color: #ff6a6a;
  max-width: 100%;
  opacity: 0.9;
  padding: 10px;
  margin: 0px 0 7px;
  z-index: 200;
}
.banner .banner-container .banner-content .showcase-header .title-header .links-wrapper a h1 {
  font-size: 52px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #fff !important;
  border-radius: 8px;
  white-space: nowrap;
  text-align: start;
  line-height: 1.2;
}
.banner .banner-container .banner-content .showcase-header .title-header .links-wrapper .title-icon {
  padding: 10px 30px 10px 0;
}
.banner .banner-container .banner-content .showcase-header .title-header .links-wrapper .title-icon .icon {
  font-size: 46px;
  color: rgb(12, 203, 142);
}
.banner .banner-container .banner-content .showcase-header .content-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  z-index: 1;
}
.banner .banner-container .banner-content .showcase-header .content-header p {
  font-size: 18px;
  color: #ffffff;
  padding: 10px;
  max-width: 420px;
  margin: 0;
  line-height: 1.5rem;
}
.banner .banner-container .banner-content .showcase-header .content-header .para-wrapper {
  background-color: #27334e;
  opacity: 0.8;
  border-radius: 12px;
}
.banner .banner-container .banner-content .showcase-header .content-header a {
  width: 50%;
  margin: 15px 0;
  background-color: #f8f8f8;
  border: 1px solid #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  opacity: 0.9;
  border-radius: 12px;
}
.banner .banner-container .banner-content .showcase-header .content-header a button {
  width: 100%;
  color: #000;
  border-radius: 0;
  font-size: 16px;
}
.banner .banner-container .banner-content .showcase-header .content-header a svg {
  font-size: 16px;
  color: #000;
}
.banner .banner-container .container-bottom-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
}
.banner .banner-container .container-bottom-img img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (max-width: 385px) {
  .banner .banner-container {
    min-height: 165vh;
  }
}
@media screen and (min-width: 500px) {
  .banner .banner-container {
    min-height: 155vh;
    max-width: 95%;
    margin: 0 0 0 auto;
  }
  .banner .banner-container .container-img {
    top: 5%;
  }
  .banner .banner-container .banner-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .banner .banner-container .banner-content .showcase-header {
    padding-left: 3rem;
    padding-top: 14rem;
  }
}
@media screen and (min-width: 785px) {
  .banner .banner-container {
    min-height: 120vh;
  }
  .banner .banner-container .banner-content .showcase-header .title-header .links-wrapper a h1 {
    font-size: 58px;
  }
  .banner .banner-container .container-bottom-img {
    width: 60%;
  }
}
@media screen and (min-width: 1024px) {
  .banner .banner-container .banner-content .showcase-header .title-header .links-wrapper a h1 {
    font-size: 67px;
  }
  .banner .banner-container .container-bottom-img {
    width: 45%;
  }
}
@media screen and (min-width: 1024px) and (min-height: 1280px) {
  .banner .banner-container .container-bottom-img {
    width: 85%;
  }
}
@media screen and (min-width: 1200px) {
  .banner .banner-container {
    max-width: 97%;
    min-height: 100vh;
  }
  .banner .banner-container .container-bottom-img {
    width: 55%;
    height: auto;
  }
}
@media screen and (min-width: 1400px) {
  .banner .banner-container {
    min-height: 90vh;
  }
}/*# sourceMappingURL=Showcase.css.map */