@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

a {
  font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar {
  display: none;
}
.services-section-comp {
  display: inline-block;
  width: 90%;
  height: 100%;
  padding: 0px 20px 20px;
  overflow: hidden;
  margin: 0 auto;
  .container {
    position: relative;
    max-width: 100%;
    .image {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 280px;
        object-fit: cover;
      }
    }

    .btn-link {
      outline: none;
      .button {
        padding: 10px 0;
        width: 100%;
        height: auto;
        color: #f8f8f8;
        background-color: #007bff;
        border: none;
        text-align: center;
        font-size: 16px;
        border-radius: 0;
      }
    }

    .bullets {
      display: none;

      @media (min-width: 768px) {
        display: block;
        ul {
          list-style-type: disc;
          padding-left: 20px;
          margin-top: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .services-section-comp {
    width: 80%;
    .container {
      .image {
        img {
          height: 340px;
        }
      }
    }
  }
}

@media screen and (min-width: 685px) {
  .services-section-comp {
    width: 95%;
    padding: 0;
    margin: 10px 0;
    .container {
      .image {
        img {
          height: 280px;
        }
      }
    }
  }
}

@media screen and (min-width: 930px) {
  .services-section-comp {
    padding: 0;
    margin: 10px 0;
    .container {
      .image {
        img {
          height: 340px;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .services-section-comp {
    width: 100%;
    .container {
      .image {
        img {
          height: 260px;
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .services-section-comp {
    .container {
      .image {
        img {
          height: 300px;
        }
      }
    }
  }
}
