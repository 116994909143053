@media screen and (min-width: 785px) {
  .main-footer {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: auto;
    grid-template-areas: 'footer nsl';
    position: relative;
    .news-letter {
      grid-area: nsl;
    }
    .footer {
      grid-area: footer;
    }
  }
}

@media screen and (min-width: 1024px) {
  .main-footer {
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: auto;
    grid-template-areas: 'footer nsl';
    position: relative;
    .news-letter {
      grid-area: nsl;
    }
    .footer {
      grid-area: footer;
    }
  }
}
