@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
.programs-card {
  background-color: #ffffff;
  border-radius: 0px;
  box-shadow: 0 6px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.programs-card .course-image img {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}
.programs-card .course-header {
  padding: 15px;
}
.programs-card .course-header h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #27334e;
  text-transform: capitalize;
}
.programs-card .course-header p {
  margin-top: 15px;
  color: #333;
  font-size: 16px;
  line-height: 1.6;
}
.programs-card .course-details {
  padding: 15px;
}
.programs-card .course-details a {
  width: 100%;
  padding: 15px;
  margin: 20px 0;
  background-color: #2e466f;
  color: #f8f8f8;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}
.programs-card .course-details a:hover {
  background-color: #1e2e49;
  color: #f8f8f8;
}
.programs-card .course-details .line-break {
  width: 100%;
  height: 3px;
  margin: 0 auto;
  background-color: #ff6a6a;
  border-radius: 0;
}
.programs-card .course-details .event-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  margin: 20px auto 0;
}
.programs-card .course-details .event-content-container .event-content {
  text-align: center;
  margin: 10px 0;
}
.programs-card .course-details .event-content-container .event-content span {
  font-size: 16px;
  padding: 0 5px;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
}
.programs-card .course-details .event-content-container .event-content svg {
  font-size: 18px;
  color: #6c6c6c;
}

@media screen and (min-width: 784px) {
  .course-card .course-image img {
    height: 280px;
  }
}/*# sourceMappingURL=ProgramsCard.css.map */