@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;400&display=swap");
.newsletter {
  background-color: #171613;
  grid-area: nsl;
  height: 100%;
}
.newsletter .container {
  padding: 50px 15px 10px;
  z-index: 950;
}
.newsletter .container .title {
  color: #fff;
  font-family: "Jost", sans-serif;
}
.newsletter .container .title h2 {
  text-transform: capitalize;
}
.newsletter .container .title p {
  color: #f8f8f8;
  margin-top: 5px;
}
.newsletter .container .title .newsletter-color {
  text-transform: capitalize;
  color: #ff6a6a;
}
.newsletter .container .input {
  margin: 20px 0;
}
.newsletter .container .input form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.newsletter .container .input form input {
  font-size: 16px;
  padding: 15px;
  border: none;
  outline: none;
  border-radius: 0px;
}
.newsletter .container .input form button {
  font-size: 16px;
  width: 100%;
  background-color: #ff6a6a;
  color: #fff;
  padding: 15px;
  margin-top: 10px;
  border-radius: 0px;
}
.newsletter .container .socials {
  margin: 30px 0;
}
.newsletter .container .socials .icon {
  color: #718191;
  font-size: 32px;
  margin-right: 10px;
}

@media screen and (min-width: 580px) {
  .newsletter .container {
    width: 90%;
    margin: 0 auto;
  }
}/*# sourceMappingURL=NewsLetter.css.map */