.community-events {
  .call-to-action-btn {
    background-color: #343e4a;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      text-align: center;
      color: #fff;
      font-size: 20px;
      max-width: 600px;
      margin: 0 auto;
    }
    button {
      margin: 10px 0 0;
      padding: 0.75rem 1.5rem;
      border-radius: 4rem;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      border: 1px solid #00c99f;
      color: #fff;
    }
  }
  background-color: #222931;
  .community-events-container {
    padding: 20px 20px 80px;
    overflow: hidden;
    max-width: 100%;
    .main-header {
      margin: 30px 0;
      width: 100%;
      .wrapper {
        margin: 10px 0;
        .main-header-content {
          max-width: 600px;
          .title {
            color: #fff;
            font-weight: 400;
            font-size: 32px;
          }
          p {
            font-size: 16px;
            padding-top: 10px;
            color: rgb(208, 208, 208);
          }
        }
        .btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          button {
            width: 100%;
            margin: 20px 0 0;
            padding: 0.75rem 1.5rem;
            border-radius: 4rem;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
            white-space: nowrap;
            cursor: pointer;
            border: 1px solid #00c99f;
            color: #fff;
          }
          .bg-btn {
            background-color: #00c99f;
          }
          .border-btn {
            color: #00c99f;
          }
        }
      }
    }
    .image {
      margin-top: 30px;
      max-width: 100%;
      height: 100%;
      img {
        border-radius: 20px;
        border: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (min-width: 560px) {
  .community-events {
    .community-events-container {
      max-width: 90%;
      margin: 30px auto 0;
      padding: 20px 0 85px;
    }
  }
}

@media screen and (min-width: 785px) {
  .community-events {
    .community-events-container {
      .main-header {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        .image {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .community-events {
    .community-events-container {
      max-width: 90%;
      .main-header {
        .wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          .main-header-content {
            .title {
              font-size: 36px;
            }
            p {
              font-size: 18px;
            }
          }
          .btn {
            button {
              margin: 0;
              font-size: 18px;
            }
          }
        }
        .image {
          height: 380px;
          img {
            border-radius: 50px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .community-events {
    .community-events-container {
      max-width: 85%;
      .main-header {
        .wrapper {
          flex-direction: column;
          align-items: flex-start;
          max-width: 500px;
          margin: 10px auto;
          .btn {
            button {
              margin: 20px 0 0;
            }
          }
        }
        .image {
          height: 100%;
        }
      }
    }
  }
}
