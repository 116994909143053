.education-services {
  margin-top: 50px;
}
.education-services .sub-header {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.education-services .sub-header .title {
  text-align: center;
  color: #fff;
  font-weight: 400;
  font-size: 32px;
}
.education-services .sub-header p {
  padding-top: 10px;
  text-align: center;
  max-width: 600px;
  color: rgb(208, 208, 208);
}
.education-services .content ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.education-services .content ul li {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
  position: relative;
}
.education-services .content ul li .icon-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.education-services .content ul li .icon-div .line {
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #fff;
}
.education-services .content ul li .icon-div .icon-wrapper {
  position: relative;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #213232;
  color: #ff6a6a;
  z-index: 1;
}
.education-services .content ul li .icon-div .icon-wrapper .icon {
  font-size: 32px;
}
.education-services .content ul li .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px 0;
}
.education-services .content ul li .content-wrapper .content-title {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
}
.education-services .content ul li .content-wrapper .content-desc {
  margin-top: 10px;
  color: rgb(208, 208, 208);
  font-size: 16px;
}

@media screen and (min-width: 785px) {
  .education-services .content ul {
    flex-direction: row;
  }
  .education-services .content ul li {
    flex-basis: 50%;
    padding: 0 10px;
  }
}
@media screen and (min-width: 1024px) {
  .education-services .content ul {
    max-width: 85%;
  }
}/*# sourceMappingURL=EducationServices.css.map */