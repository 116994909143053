.about .call-to-action-btn {
  background-color: #ec915c;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about .call-to-action-btn h1 {
  text-align: center;
  color: #fff;
  font-size: 20px;
}
.about .call-to-action-btn button {
  margin: 10px 0 0;
  padding: 0.75rem 1.5rem;
  border-radius: 4rem;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #fff;
  color: #fff;
}
.about .container {
  position: relative;
}
.about .container p {
  opacity: 0.9;
}
.about .container li {
  list-style: none;
}
.about .container .about-banner {
  position: relative;
}
.about .container .about-banner img {
  width: 100%;
  height: 260px;
  -o-object-fit: cover;
     object-fit: cover;
}
.about .container .overlay {
  background-image: linear-gradient(359.8deg, #3b4755 2.2%, #222931 99.3%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about .container .overlay .title {
  flex: 1;
  color: #fff;
  max-width: 100%;
  padding: 0 20px;
  position: relative;
}
.about .container .title {
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
.about .container .team {
  overflow: hidden;
}
.about .container .team .title {
  color: #fff;
  padding: 50px;
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
}
.about .container .values {
  padding: 0px 0 20px;
  background-image: linear-gradient(174.2deg, rgb(255, 244, 228) 7.1%, rgb(240, 246, 238) 67.4%);
}
.about .container .values .title {
  color: #fff;
  padding: 50px;
  background-color: #4158d0;
  background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
}
.about .container .values .value-content-wrapper {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
  max-width: 100%;
  margin: 0 auto;
}
.about .container .values .value-content-wrapper .section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}
.about .container .values .value-content-wrapper .section h2 {
  font-size: 1.4rem;
  font-weight: 500;
}
.about .container .values .value-content-wrapper .section p {
  color: #424242;
  padding: 5px 0 0;
  font-size: 16px;
  line-height: 1.4rem;
}

@media screen and (min-width: 500px) {
  .about .container .about-banner img {
    height: 300px;
  }
}
@media screen and (min-width: 784px) {
  .about .container .about-banner img {
    height: 300px;
  }
  .about .container .values .value-content-wrapper {
    max-width: 80%;
    flex-direction: row;
  }
  .about .container .values .value-content-wrapper .section {
    flex-basis: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .about .container .about-banner img {
    height: 360px;
  }
  .about .container .values .value-content-wrapper {
    max-width: 80%;
  }
  .about .container .values .value-content-wrapper .section {
    flex-basis: 33.33%;
    padding: 20px 30px;
  }
}
@media screen and (min-width: 1200px) {
  .about .container .about-banner img {
    height: 420px;
  }
  .about .container .values .value-content-wrapper {
    max-width: 70%;
  }
}/*# sourceMappingURL=About.css.map */