@media screen and (min-width: 984px) {
  .large-screen-menu {
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: 100%;
    z-index: 100;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #27334e;
      opacity: 0.1;
    }
    .menu-container {
      padding-right: 30px;
      .modal-logo {
        display: none;
      }
      .main-menu-list {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        li {
          position: relative;
          font-size: 20px;
          font-weight: 400;
          text-transform: capitalize;
          padding: 0px 20px;
          list-style: none;
          font-family: 'rustica';
          cursor: pointer;
          color: #fff;
          .sub__menu {
            position: absolute;
            top: 100%;
            left: 0;
            background-color: #27334e;
            padding: 10px 20px 10px 10px;
            margin-top: 10px;
            li {
              color: #f8f8f8;
              padding: 10px 0;
              width: 100%;
              a {
                display: flex;
                justify-content: start;
                align-items: center;
                width: 100%;
                .icon {
                  margin-right: 10px;
                  color: #ffbd59;
                }
                span {
                  width: 100%;
                }
              }
            }
          }
        }
        .services-li {
          .services-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
              background-color: #151c2c;
              margin-left: 5px;
              color: #ffbd59;
            }
          }
        }
        .underline__services__wrapper {
          border: 0;
        }
      }
      .nav-call-to-action-btn {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
}
