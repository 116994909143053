@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
.services__content {
  overflow: hidden;
  position: relative;
  padding: 0 20px;
  max-width: 100%;
  height: 100%;
}
.services__content .grid-container .grid-content {
  padding: 20px 10px;
  max-width: 100%;
  margin: 20px 0;
  border-radius: 20px;
  background-color: rgb(44, 62, 78);
}
.services__content .grid-container .grid-content p {
  color: #f8f8f8;
  max-width: 600px;
  margin: 0 0 10px;
  line-height: 1.5rem;
  font-size: 16px;
}
.services__content .grid-container .grid-content a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background-color: #f95050;
}
.services__content .grid-container .grid-content a svg {
  font-size: 16px;
  color: #fff;
}
.services__content .grid-container .grid-content a .button {
  width: auto;
  color: #f8f8f8;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}
.services__content .grid-container .grid-content a .button:hover {
  background-color: #eb1b24;
  color: #f8f8f8;
}
.services__content .grid-container .grid-content a:hover {
  background-color: #eb1b24;
  color: #f8f8f8;
}
.services__content .grid-container .grid-collection {
  position: relative;
}
.services__content .grid-container .grid-collection video {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: none;
  outline: none;
  width: 100%;
  height: 260px;
  -o-object-fit: cover;
     object-fit: cover;
}
.services__content .grid-container .grid-collection .wrapper {
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  padding: 3rem 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.services__content .grid-container .grid-collection .wrapper .content-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}
.services__content .grid-container .grid-collection .wrapper .content-section h2 {
  font-size: 1.7rem;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: #f8f8f8;
  font-weight: bold;
}
.services__content .grid-container .grid-collection .wrapper .content-section p {
  margin-bottom: 16px;
  color: #f8f8f8;
}

@media screen and (min-width: 1024px) {
  .services__content .grid-container .grid-collection video {
    height: 300px;
  }
  .services__content .grid-container .grid-collection .wrapper .content-section {
    max-width: 80%;
  }
}
@media screen and (min-width: 1200px) {
  .services__content .grid-container .grid-collection video {
    height: 320px;
  }
}/*# sourceMappingURL=ServicesContent.css.map */